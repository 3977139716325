.result-container {
  background: #155acf;
  /* autoprefixer: off */
  height: -webkit-fill-available; 
  margin-bottom: 0px;
  padding-bottom: 0;
  overflow-y: hidden;
}
.plan-details-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.plan-details-icon {
  margin-right: 15px;
}
.plan-details-text {
  color: #160d2e;
  font-size: 14px;
  line-height: 22px;
}
/* note info card */
.infocard {
  margin: 20px 0;
}
.infocard .title {
  font-size: 20px;
  color: #160d2e;
  font-weight: 600;
  padding: 0px 0px 0 0px;
}
.highlight-text {
  background: var(--highlight);
  padding: 15px 15px 20px 15px;
  border: 1px solid var(--highlight);
  border-radius: 4px;
}
.highlight-text-myway {
  background: #f0f7ff;
  padding: 15px 15px 20px 15px;
  border: 1px solid #f6f2ff;
  border-radius: 4px;
}
.highlight-text1 {
  display: -ms-flexbox;
  display: flex;
  line-height: 20px;
  -ms-flex-pack: start;
      justify-content: start;
  -ms-flex-align: center;
      align-items: center;
}
.highlight-text11 {
  width: 14px;
}
.highlight-text12 {
  font-size: 14px;
  color: #160d2e;
  font-weight: 700;
  margin: 0px 0 0 7px;
}
.highlight-text2 {
  color: #160d2e;
  font-size: 14px;
  margin: 4px 0 0 20px;
}
.sip-details {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 15px;
}
.sip-details:nth-child(odd) {
  background-color: #faf7ff;
}
.sip-details-header {
  padding: 15px;
  color:#0A1C32;
  font-weight: 500;
  font-size: 20px;
}
.sip-details .left {
  width: 33%;
  text-align: left;
  color: #767e86;
}
.sip-details .right {
  width: 33%;
  text-align: right;
}
.display-flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.bankcard {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
}
.bankcard.selected {
  background: #f4f4f4;
}

.success-img {
  text-align: center;
  margin: 10px 0 0px 0;
}
.success-text {
  text-align: center;
  margin: 50px 0 20px 0;
  color: #160d2e;
  font-size: 16px;
  font-weight: 500;
}
.success-text-info {
  margin-bottom: 6px;
  text-align: center;
  font-size: 16px;
  color: #767e86;
}
.success-enps {
  margin: 20px 0;
}
.success-bottom {
  margin: 56px 0 20px 0;
  text-align: center;
}
.success-bottom1 {
  font-size: 11px;
  color: #767E86;
  margin-bottom: 14px;
  font-weight: 500;
  line-height: 13.04px;
  letter-spacing: 1px;
}
.success-bottom2 {
  display: -ms-flexbox;
  display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
}
.success-bottom2a {
  font-size: 10px;
  color: #767E86;
  font-weight: 700;
  line-height: 11.72px;
  letter-spacing: 1px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.success-bottom2b {
  margin: 0 40px 0 40px;
  color: #767E86;
}
.payment-failed-subtitle {
  padding: 0 25px;
}

.iframe-mandate-fail-text{
  margin-top: 140px;
}

.iframe-mandate-fail-text .payment-failed-title {
  text-align: left !important;
}

.iframe-mandate-fail-text .payment-failed-subtitle {
  text-align: left !important;
  padding-left: 0px;
}

.iframe-e-mandate-success .success-text{
  margin-top: 140px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
}

.iframe-e-mandate-success .success-text-info{
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color:#767E86;
  text-align: left;
}



